var render = function render(){var _vm=this,_c=_vm._self._c;return _c('li',{directives:[{name:"expose",rawName:"v-expose",value:({
    id: '1-8-3-45',
    data: { index: _vm.index, item: _vm.cart, behaviorText: _vm.behaviorText }
  }),expression:"{\n    id: '1-8-3-45',\n    data: { index, item: cart, behaviorText }\n  }"},{name:"tap",rawName:"v-tap",value:({
    id: '1-8-8-6',
    data: { 
      index: _vm.index, 
      item: { 
        ..._vm.cart, 
        ..._vm.cart.product,
        sale_price: _vm.cart.product.salePrice,
        retail_price: _vm.cart.product.retailPrice
      }, 
      behaviorText: _vm.behaviorText 
    }
  }),expression:"{\n    id: '1-8-8-6',\n    data: { \n      index, \n      item: { \n        ...cart, \n        ...cart.product,\n        sale_price: cart.product.salePrice,\n        retail_price: cart.product.retailPrice\n      }, \n      behaviorText \n    }\n  }"}],staticClass:"shopping-bag__item-wrap",attrs:{"da-expose-code":"placeOrderProductBox"}},[_c('div',{staticClass:"item-content mshe-flexbetween"},[_c('div',{staticClass:"mbag-left"},[_c('CommonImage',{staticClass:"left-img",attrs:{"item":_vm.cart,"cut-img-width":80},scopedSlots:_vm._u([{key:"others",fn:function(){return [(_vm.isSensitiveGood)?_c('img',{staticClass:"item-sensitive",attrs:{"src":`${_vm.PUBLIC_CDN}/pwa_dist/images/checkout/sui_icon_sensitive-f1bf4c1570.svg`}}):_vm._e(),_vm._v(" "),(_vm.isOutStockGood)?_c('div',{staticClass:"goods-out-stock"},[_c('div',{staticClass:"goods-out-stock__content"},[_c('i',{staticClass:"iconfont icon-SOLDOUT"}),_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm.language.SHEIN_KEY_PWA_14987))])])]):_vm._e(),_vm._v(" "),_c('ShoppingBagItemBelt',{staticClass:"shopping-bag__item-belt",attrs:{"item":_vm.cart,"isSheinClubGift":_vm.isSheinClubGiftItem,"lagreShipInfo":_vm.lagreShipInfo,"isOutStockGood":_vm.isOutStockGood,"isGiftGoods":_vm.isGiftGoods,"isAddBuyGoods":_vm.isAddBuyGoods,"inDrawer":_vm.inDrawer}}),_vm._v(" "),(_vm.showDiscountLabel)?[(_vm.cart.product.unit_discount > 0)?_c('div',{class:[
                _vm.discountLogoByAbt ? 'cart-discount-v2' : 'new-discount',
                _vm.isPremiumMember ? {
                  'premium-member-discount': _vm.isPremiumMember,
                } : {
                  'fs-discount': _vm.isFlashDiscount,
                  'fs-new-discount': _vm.isFlashNewDiscount,
                  'brand-flash': _vm.isBrandFlash
                },
                {'club-gift-discount': _vm.isSheinClubGiftItem}
              ],attrs:{"aria-hidden":"true"}},[(_vm.GB_cssRight)?_c('span',[_vm._v("-%"+_vm._s(_vm.cart.product.unit_discount))]):_c('span',[_vm._v("-"+_vm._s(_vm.cart.product.unit_discount)+"%")])]):_vm._e()]:[(!_vm.isInversion && _vm.cart.product.suggested_unit_discount > 0)?_c('div',{class:[
                _vm.discountLogoByAbt ? 'cart-discount-v2' : 'new-discount',
                _vm.isPremiumMember ? {
                  'premium-member-discount': _vm.isPremiumMember,
                } : {
                  'fs-discount': _vm.isFlashDiscount,
                  'fs-new-discount': _vm.isFlashNewDiscount,
                  'brand-flash': _vm.isBrandFlash
                }
              ],attrs:{"aria-hidden":"true"}},[(_vm.GB_cssRight)?_c('span',[_vm._v("-%"+_vm._s(_vm.cart.product.suggested_unit_discount))]):_c('span',[_vm._v("-"+_vm._s(_vm.cart.product.suggested_unit_discount)+"%")])]):_vm._e()]]},proxy:true}])})],1),_vm._v(" "),_c('div',{staticClass:"mbag-right mshe-flexbetween"},[_c('div',[(_vm.chemicalsIds.includes(_vm.cart.product.goods_sn))?_c('div',{staticClass:"warning-tip title-inner",on:{"click":function($event){$event.stopPropagation();return _vm.showP65Warning(_vm.cart.product.goods_sn)}}},[_c('img',{attrs:{"src":_vm.IMG_LINK['warning']}}),_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm.language.SHEIN_KEY_PWA_20813))])]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"title-wrapper"},[(_vm.goodsNameBeforeLabelIcon)?_c('img',{staticClass:"black-five-label",attrs:{"src":_vm.goodsNameBeforeLabelIcon,"alt":""}}):_vm._e(),_vm._v(" "),_c('div',{staticClass:"gd-name",domProps:{"innerHTML":_vm._s(_vm.cart.product.goods_name)}})]),_vm._v(" "),(_vm.skuSaleAttrText(_vm.cart))?_c('div',{staticClass:"gd-size-color"},[(_vm.cart.product.main_sale_attr_show_mode == 2 && _vm.cart.product.color_image && _vm.cart.product.relatedColor && _vm.cart.product.relatedColor.length)?_c('span',{staticClass:"value"},[_c('img',{staticClass:"color-img",attrs:{"src":_vm.transformImg({img: _vm.cart.product.color_image})}})]):_vm._e(),_vm._v(" "),_c('span',{staticClass:"attr"},[_vm._v(_vm._s(_vm.skuSaleAttrText(_vm.cart)))])]):_c('div',{staticClass:"gd-size-color"},[(_vm.cart.product.size || _vm.cart.product.color_image)?[_c('span',{staticClass:"value"},[_vm._v(_vm._s(_vm.getProductSize({ 
                abt: _vm.defaultLocalSizeByAbt,
                sizeAttr: _vm.cart.product.sku_sale_attr?.find(attr => +attr.attr_id === 87),
                sizeRuleList: _vm.cart.attr_value_location?.size_rule_list,
                cacheLocalCountry: _vm.cacheLocalCountry
              })))]),_vm._v(" "),(_vm.cart.product.size && _vm.cart.product.color_image)?[_vm._v("\n              /\n            ")]:_vm._e(),_vm._v(" "),(_vm.cart.product.color_image)?_c('span',{staticClass:"value"},[_c('img',{staticClass:"color-img",attrs:{"src":_vm.transformImg({img: _vm.cart.product.color_image})}})]):_vm._e()]:[_c('span')]],2),_vm._v(" "),_c('CartItemBehavior',{staticStyle:{"margin-top":".16rem"},attrs:{"language":_vm.checkoutLanguage,"label":_vm.cart.product.showBehaviorLabel}}),_vm._v(" "),(!_vm.isSheinClubGiftItem)?_c('cart-item-product-tag',{style:({'margin-top': '.16rem', width: 'auto'}),attrs:{"language":_vm.checkoutLanguage,"item":_vm.cart,"is-new-cart":true,"free-lang":_vm.language.SHEIN_KEY_PWA_18337,"abt-params":_vm.abtParams,"page":"checkout","showQuickShip":false,"showFreeShipping":!_vm.IS_RW && +_vm.cart.freight_free_rule_id > 0,"isSuggested":_vm.isSuggested,"show-evolu-shein":_vm.showEvoluShein,"show-sfs-tag":_vm.showSfsTag},scopedSlots:_vm._u([{key:"checkout-tag",fn:function(){return [(!!(_vm.cart.quick_ship && _vm.showQuickShipByAbt && _vm.qsMallList.last_quickship_tag) || !!(_vm.Need_Qs_Freight && _vm.showQuickShipByAbt && _vm.qsMallList.last_quickship_tag))?_c('QuickShipTag',{attrs:{"cartQsTagInfo":{
                ..._vm.quickShipInfoTag.qsDataProps,
                qsTimelinessInfo: {
                  isLocationTop: false,
                }
              },"qsMallList":_vm.qsMallList,"currentItemNo":_vm.cart.id}}):_vm._e()]},proxy:true}],null,false,661678795)}):_vm._e()],1),_vm._v(" "),_c('div',{staticClass:"gd-info"},[_c('div',{staticClass:"gd-info__left",class:{
            'gd-info__left_row': _vm.showRRP && (_vm.isInversion || !_vm.isShowDelPrice)
          }},[_c('div',{staticClass:"checkout-shopping-bag__price"},[(_vm.showDiscountType)?[(_vm.isPremiumMember || _vm.isSheinClubGiftItem)?_c('Icon',{attrs:{"name":"sui_icon_club_logo_fill_14px","size":"14px","color":_vm.discountLogoByAbt ? '#873C00' : '#C96E3F'}}):[(_vm.promotionIcon)?_c('i',{staticClass:"suiiconfont",class:[_vm.promotionIcon, _vm.promotionIcon == 'sui_icon_flashsale_15px' ? 'iconfont-yellow' : 'iconfont-red']}):(_vm.isBrandFlash)?_c('Icon',{attrs:{"name":"sui_icon_brandsale_15px","size":"16px","color":"#FFCD94"}}):_vm._e()],_vm._v(" "),(!_vm.IS_RW && _vm.isVipItem)?_c('em',{staticClass:"vip-exclusive-tag_color-black",class:[_vm.GB_cssRight ? 'vip-exclusive-tag__ar' : 'vip-exclusive-tag']}):_vm._e()]:_vm._e(),_vm._v(" "),(_vm.IS_RW && _vm.isVipItem)?_c('vip-price',{attrs:{"price":_vm.cart.unitPrice.amountWithSymbol}}):(_vm.isSuggestedInDe)?_c('div',{staticClass:"checkout-shopping-bag__price-detail",class:[
                ..._vm.priceClassName,
                'column-direction'
              ]},[_c('div',{staticClass:"checkout-shopping-bag__price-content"},[_c('span',{staticClass:"checkout-shopping-bag__price-unit"},[_vm._v("\n                  "+_vm._s(_vm.getPriceByAbt)+"\n                ")]),_vm._v(" "),(_vm.showDiscountInDe)?[([1, 3].includes(_vm.productPriceScene))?_c('del',{staticClass:"checkout-shopping-bag__price-delete"},[_vm._v("\n                    "+_vm._s(_vm.crossPrice)+"\n                  ")]):_vm._e(),_vm._v(" "),([3].includes(_vm.productPriceScene))?_c('s-popover',{ref:"priceTipsRef",staticClass:"checkout-shopping-bag__tips",attrs:{"append-to-body":!_vm.inDrawer,"prop-style":{
                      width: '6.4rem'
                    },"placemen":_vm.popoverPlacement,"show-close-icon":""},on:{"opened":function($event){return _vm.handlePopoverOpened()},"beforeClose":function($event){return _vm.handlePopoverClose()}},model:{value:(_vm.priceTipsVisible),callback:function ($$v) {_vm.priceTipsVisible=$$v},expression:"priceTipsVisible"}},[_c('Icon',{attrs:{"slot":"reference","name":"sui_icon_info_12px_2","size":"12px","color":"#959595"},slot:"reference"}),_vm._v(" "),_c('div',{staticClass:"checkout-shopping-bag__tips-content"},[_vm._v("\n                      "+_vm._s(_vm.language.SHEIN_KEY_PWA_31354)+"\n                    ")])],1):_vm._e()]:_vm._e()],2),_vm._v(" "),([1, 5].includes(_vm.productPriceScene))?_c('div',{staticClass:"cross-text",domProps:{"innerHTML":_vm._s(_vm.crossPriceTipsInDe)}}):_vm._e()]):_c('span',{staticClass:"checkout-shopping-bag__price-detail",class:_vm.priceClassName},[_vm._v("\n              "+_vm._s(_vm.getPriceByAbt)+"\n              "),(!_vm.isInversion && _vm.isShowDelPrice)?[(_vm.isSuggested != 'special')?_c('del',[_vm._v("\n                  "+_vm._s(_vm.crossPrice)+"\n                ")]):_c('span',{staticClass:"cross-text",domProps:{"innerHTML":_vm._s(_vm.crossPriceTips)}})]:_vm._e()],2)],2),_vm._v(" "),(_vm.showRRP)?_c('div',{staticClass:"checkout-shopping-bag__rrp"},[_vm._v("\n            "+_vm._s(_vm.rrp)+"\n          ")]):_vm._e()]),_vm._v(" "),(_vm.isShowEditBtn)?_c('InputNumber',{staticClass:"checkout-shopping-bag__input",attrs:{"item":_vm.cart,"isLastGoods":_vm.isLastGoods,"source":"inner","isSheinClubGiftItem":_vm.isSheinClubGiftItem},nativeOn:{"click":function($event){$event.stopPropagation();}}}):_c('div',{staticClass:"gd-quantity",class:{'gd-quantity-welfare': +(_vm.cart.quantity >= 2)}},[_c('span',{staticClass:"value"},[_vm._v("x"+_vm._s(_vm.cart.quantity))])])],1)])]),_vm._v(" "),_c('ItemFooterTips',{attrs:{"item":_vm.cart}}),_vm._v(" "),(_vm.isCustomCart)?_c('s-alert',{directives:[{name:"tap",rawName:"v-tap",value:({
      id: '1-11-1-174', 
      data: _vm.customAnalysis
    }),expression:"{\n      id: '1-11-1-174', \n      data: customAnalysis\n    }"}],staticClass:"shopping-bag__item-custom",attrs:{"type":"info","jumpable":true},nativeOn:{"click":function($event){$event.stopPropagation();return _vm.openPreviewDialog.apply(null, arguments)}}},[_c('p',[_vm._v("\n      "+_vm._s(_vm.language.SHEIN_KEY_PWA_26444)+"\n    ")])]):_vm._e(),_vm._v(" "),(_vm.largeShipTips)?_c('div',{staticClass:"gd-footer large-tip"},[_c('Icon',{attrs:{"name":_vm.lagreShipInfo.largeShipIcon,"size":'12px',"color":'#198055'}}),_vm._v(" "),_c('span',{staticClass:"large-tip-text",domProps:{"innerHTML":_vm._s(_vm.largeShipTips)}})],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }